import { WppSpinner, WppInlineMessage } from '@platform-ui-kit/components-library-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import styles from 'components/assistant/chat/Chat.module.scss'
import { Flex } from 'components/common/flex/Flex'
import { useChatContext } from 'hooks/useChatContext'
import { useConversationContext } from 'hooks/useConversationContext'

import { AnswerLoadingPlaceholder } from './answerLoadingPlaceholder/AnswerLoadingPlaceholder'
import { ChatBottomText } from './chatBottomText/ChatBottomText'
import { ChatBubble } from './chatBubble/ChatBubble'
import { Question } from './chatBubble/question/Question'
import { TextAnswer } from './chatBubble/textAnswer/TextAnswer'
import { ChatInstructions } from './chatInstructions/ChatInstructions'

interface Props {
  handlePromptClick: (prompt: string) => void
  scrollToView: (id?: string) => void
}

const ChatDisplay = ({ handlePromptClick, scrollToView }: Props) => {
  const { t } = useTranslation()
  const { conversation } = useConversationContext()
  const {
    refScrollBottomDiv,
    refBubbles,
    question,
    firstQuestion,
    initTab,
    isFetchingConversationMessages,
    answerIsLoading,
    answerError,
    showTokenLimitReached,
    conversationMessages,
    listOfAllSteps,
    listOfCurrentSteps,
    setFeedbackType,
    onFeedbackUpdate,
  } = useChatContext()

  const isFirstContentEmpty = conversationMessages.length > 0 && conversationMessages[0].role === 'system'

  const conversationMessagesFiltered = isFirstContentEmpty ? conversationMessages.slice(1) : conversationMessages
  const [showLoader, setShowLoader] = useState(true)
  const currentTab = useConversationContext().tab

  useEffect(() => {
    initTab && initTab !== currentTab ? setShowLoader(false) : setShowLoader(true)
  }, [currentTab, initTab])

  return (
    <div className={styles.chatWrapper}>
      {isFetchingConversationMessages && conversationMessages.length < 1 && !firstQuestion && (
        <Flex className={styles.chat} justify="center" align="center">
          <WppSpinner size="m" />
        </Flex>
      )}
      {(!isFetchingConversationMessages || firstQuestion || conversationMessages.length > 0) && (
        <div className={styles.chat}>
          {!firstQuestion && !conversation && (
            <ChatInstructions onPromptClick={handlePromptClick} question={question} />
          )}
          {firstQuestion && currentTab !== 'HISTORY' && (
            <div className="cancel-drag">
              <Question text={firstQuestion} />
            </div>
          )}
          {conversationMessagesFiltered.map((message, index) => {
            if (message.type === 'failure') return <TextAnswer text={t('assisstant_errors.no_answer')} isError />
            else
              return (
                <div key={index} ref={(refBubbles.current[message.id] ??= { current: null })}>
                  <ChatBubble
                    message={message}
                    onOpenFeedback={() => scrollToView(message.id)}
                    onFeedbackUpdate={onFeedbackUpdate}
                    setFeedbackType={setFeedbackType}
                  />
                </div>
              )
          })}
          {answerIsLoading && showLoader && (
            <AnswerLoadingPlaceholder allSteps={listOfAllSteps} finishedSteps={listOfCurrentSteps} />
          )}
          {answerError && <WppInlineMessage size="s" message={t('question_input.errors.general')} type="error" />}
          <ChatBottomText tokenLimitReached={showTokenLimitReached} />
          <div ref={refScrollBottomDiv} />
        </div>
      )}
    </div>
  )
}

export default ChatDisplay
