import { WppTypography } from '@platform-ui-kit/components-library-react'

import styles from 'components/assistant/chat/chatBubble/fileAnswer/FileAnswer.module.scss'
import { Flex } from 'components/common/flex/Flex'
import { FileAnswerDto } from 'types/dto/ConversationDto'

interface Props {
  answer: FileAnswerDto
}

export const FileAnswer = ({ answer }: Props) => {
  return (
    <div className={styles.card}>
      <WppTypography type="2xs-strong" className={styles.documentType}>
        {answer.content.common_description}
      </WppTypography>

      {answer.content.files.map(document => (
        <a
          key={document?.title || 'Document'}
          href={document.download_url}
          target="_blank"
          className={!document ? styles.disabled : undefined}
          rel="noreferrer"
        >
          <Flex gap={12}>
            <Flex>
              <img src={document.thumbnail_url} alt={document.title} className={styles.articleImg} />
            </Flex>
            <Flex direction="column" className={styles.articleNote} gap={20}>
              <Flex direction="column">
                <WppTypography type="2xs-strong" className={styles.documentType}>
                  Case Study
                </WppTypography>
                <WppTypography type="s-midi">{document.title}</WppTypography>
              </Flex>
            </Flex>
          </Flex>
        </a>
      ))}
    </div>
  )
}
