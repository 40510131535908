// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".o9r2g{margin-bottom:12px;padding:12px;background-color:var(--wpp-grey-color-200);border-radius:var(--wpp-border-radius-m)}.hYvEx{width:98px;height:98px;border-radius:8px;object-fit:contain}.uL2Ir{position:relative;width:100%}.MmH8W{cursor:default;pointer-events:none}.QbmCz{margin-bottom:4px;text-transform:uppercase}", "",{"version":3,"sources":["webpack://./src/components/assistant/chat/chatBubble/fileAnswer/FileAnswer.module.scss"],"names":[],"mappings":"AAAA,OACE,kBAAA,CACA,YAAA,CACA,0CAAA,CACA,wCAAA,CAGF,OACE,UAAA,CACA,WAAA,CACA,iBAAA,CACA,kBAAA,CAGF,OACE,iBAAA,CACA,UAAA,CAGF,OACE,cAAA,CACA,mBAAA,CAGF,OACE,iBAAA,CACA,wBAAA","sourcesContent":[".card {\n  margin-bottom: 12px;\n  padding: 12px;\n  background-color: var(--wpp-grey-color-200);\n  border-radius: var(--wpp-border-radius-m);\n}\n\n.articleImg {\n  width: 98px;\n  height: 98px;\n  border-radius: 8px;\n  object-fit: contain;\n}\n\n.articleNote {\n  position: relative;\n  width: 100%;\n}\n\n.disabled {\n  cursor: default;\n  pointer-events: none;\n}\n\n.documentType {\n  margin-bottom: 4px;\n  text-transform: uppercase;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "o9r2g",
	"articleImg": "hYvEx",
	"articleNote": "uL2Ir",
	"disabled": "MmH8W",
	"documentType": "QbmCz"
};
export default ___CSS_LOADER_EXPORT___;
