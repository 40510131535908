import { WppTypography } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'

import { MarkdownText } from 'components/assistant/chat/chatBubble/markdownText/MarkdownText'
import styles from 'components/assistant/chat/chatBubble/textAnswer/TextAnswer.module.scss'
import { Flex } from 'components/common/flex/Flex'

interface Props {
  text: string
  isError?: boolean
}

export const TextAnswer = ({ text, isError }: Props) => {
  const { osContext } = useOs()
  const tenantUrl = osContext.tenant.homeUrl.slice(0, -1)

  const formatLinksInText = (inputString: string, baseUrl: string) => {
    const regex = /\{baseUrl:(.*?)\}/g

    const replacedString = inputString.replace(regex, (_, postfix) => {
      return `[${baseUrl}${postfix}](${baseUrl}${postfix})`
    })

    return replacedString
  }

  const textWithLinks = formatLinksInText(text, tenantUrl)

  return (
    <Flex className={styles.chatBubbleAnswer} direction="row" wrap="nowrap" gap={12}>
      <WppTypography tag="p" type="s-body" className={clsx(styles.text, isError && styles.errorText)}>
        <MarkdownText text={textWithLinks} />
      </WppTypography>
    </Flex>
  )
}
